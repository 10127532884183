import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from './baseURL';
import { CheckCircleIcon } from '@heroicons/react/24/solid'; // Heroicons for icons

const SamsungDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [product, setProduct] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({
        storageSize: null,
        colorOption: null,
        simOption: null,
        repair: [], // Initialize repair as an empty array
        otherRepairText: '',
        faults: [], // Initialize faults as an empty array
        cosmeticIssues: [], // Initialize cosmeticIssues as an empty array
        frontScreen: null,
        back: null,
        side: null,
        pta: {}, // Initialize pta as an empty object
        accessories: null,
        isFunctional: null,
        isDamaged: null,
        isRepaired: null,
        paymentOption: null,
    });
    const [finalPrice, setFinalPrice] = useState(0);
    const [instantCashValue, setInstantCashValue] = useState(0);
    const [storeCreditValue, setStoreCreditValue] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [step, setStep] = useState(1);
    const [customerDetails, setCustomerDetails] = useState({
    fullName: '',
    whatsapp: '',
    isInLahore: '',
    buyingPreference: '',
    address: '',
    ipAddress: '', // Add ipAddress field for storing user's IP address
    location: null, // Add location field for storing geolocation
    });

    const handleCustomerDetailsChange = (field, value) => {
  setCustomerDetails((prevDetails) => ({
    ...prevDetails,
    [field]: value,
  }));
};

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const calculateFinalPrice = useCallback((options = {}) => {
  let originalMaxPrice = product?.maxPrice || 0; // The original max price before any deductions
  let adjustedPrice = originalMaxPrice; // Initialize with original max price

  // Step 1: Apply PTA and Factory Unlock deductions to adjust the base price
  const ptaDeduction = options.pta['Is Your Phone Official PTA Approved?']?.deductionPercentage || 0;
  const cpidDeduction = options.pta['Is Your Phone CPID Approved?']?.deductionPercentage || 0;
  const patchedDeduction = options.pta['Is Your Phone Patched?']?.deductionPercentage || 0;

  if (ptaDeduction || cpidDeduction || patchedDeduction) {
    // Deduct from the original max price
    adjustedPrice -= Math.round(originalMaxPrice * (ptaDeduction / 100));
    adjustedPrice -= Math.round(originalMaxPrice * (cpidDeduction / 100));
    adjustedPrice -= Math.round(originalMaxPrice * (patchedDeduction / 100));
  }

  let finalPrice = adjustedPrice; // Now use this adjusted price as the new base

  // Step 2: Apply all other deductions based on the new adjusted price
  Object.keys(options).forEach((key) => {
    const option = options[key];
    if (key === 'repair' || key === 'faults' || key === 'cosmeticIssues') {
      option.forEach((selectedOption) => {
        // Deduct from the adjusted price
        finalPrice -= Math.round(adjustedPrice * (selectedOption.deductionPercentage / 100)); 
      });
    } else if (option && typeof option === 'object') {
      if (option.deductionPercentage && key !== 'pta') { // Exclude PTA deductions
        finalPrice -= Math.round(adjustedPrice * (option.deductionPercentage / 100)); // Apply deduction based on adjusted price
      }
    }
  });

  // Step 3: Apply payment option deduction (if any), based on the adjusted price
  if (options.paymentOption && options.paymentOption.deductionPercentage) {
    finalPrice -= Math.round(adjustedPrice * (options.paymentOption.deductionPercentage / 100));
  }

  setFinalPrice(finalPrice); // Set the final calculated price
}, [product]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    window.scrollTo({ top: 0, behavior: 'smooth' });

    const fetchData = async () => {
      try {
        const productResponse = await axiosInstance.get(`/api/samsung?id=${id}`);
        const productData = productResponse.data;
        setProduct(productData);
        setFinalPrice(productData.maxPrice);

        const instantCashOption = productData.paymentOptions.find((option) => option.option === 'Instant Cash');
        if (instantCashOption) {
          const instantCashDeduction = Math.round(productData.maxPrice * (instantCashOption.deductionPercentage / 100));
          setInstantCashValue(productData.maxPrice - instantCashDeduction);
        }

        setSelectedOptions((prevOptions) => ({
          ...prevOptions,
          repairOptions: productData.repairs,
          faultOptions: productData.faults,
          cosmeticIssueOptions: productData.cosmeticIssues,
          frontScreenOptions: productData.frontScreen,
          backOptions: productData.back,
          sideOptions: productData.side,
          ptaOptions: productData.pta,
          accessoriesOptions: productData.accessories,
          simOptions: productData.simVariant,
          paymentOptions: productData.paymentOptions,
        }));
      } catch (error) {
        console.error('Error fetching product details:', error);
        setErrorMessage('Failed to load product details. Please try again.');
      }
    };

    fetchData();
  }, [location.search]);

  useEffect(() => {
    if (product) {
      calculateFinalPrice(selectedOptions);
    }
  }, [selectedOptions, product, calculateFinalPrice]);

const handleSelection = (category, option, deductionPercentage = 0) => {
  setErrorMessage('');

  setSelectedOptions((prevOptions) => {
    const newOptions = { ...prevOptions };

    // Repair handling (multiple selection allowed)
    if (category === 'repair') {
      const alreadySelectedIndex = newOptions.repair.findIndex((selected) => selected.repair === option.repair);
      if (alreadySelectedIndex >= 0) {
        newOptions.repair.splice(alreadySelectedIndex, 1); // Deselect
      } else {
        newOptions.repair.push({
          repair: option.repair,
          value: option.repair,
          deductionPercentage,
        }); // Select
      }
    }
    else if (category === 'colorOption') {
  const selectedColor = product.colors.find((color) => color.color === option);
  
  // Check if the selected color is the same as the current one; if so, deselect
  if (newOptions[category]?.value === selectedColor.color) {
    newOptions[category] = null; // Deselect the color
  } else {
    // Otherwise, select the new color
    newOptions[category] = { value: selectedColor.color, deductionPercentage: selectedColor.deductionPercentage };
  }
}


    // Faults handling (multiple selection allowed)
    else if (category === 'faults') {
      const alreadySelectedIndex = newOptions.faults.findIndex((selected) => selected._id === option._id);
      if (alreadySelectedIndex >= 0) {
        newOptions.faults.splice(alreadySelectedIndex, 1); // Deselect
        // If Faulty Display is deselected, add Damaged Display back
        if (option.header === 'Faulty Display' && option.condition === 'Dead Pixels/Spots/Lines') {
          const damagedDisplayOption = product.cosmeticIssues.find((issue) => issue.header === 'Damaged Display');
          if (damagedDisplayOption && !newOptions.cosmeticIssues.some((issue) => issue.header === 'Damaged Display')) {
            newOptions.cosmeticIssueOptions.push(damagedDisplayOption);
          }
          // Reset front screen as Faulty Display is deselected
          newOptions.frontScreen = null;
          newOptions.frontScreenOptions = product.frontScreen; // Restore options
        }
      } else {
        newOptions.faults.push({
          _id: option._id,
          header: option.header,
          value: option.condition,
          deductionPercentage,
        }); // Select
        // Remove Damaged Display if Faulty Display is selected
        if (option.header === 'Faulty Display' && option.condition === 'Dead Pixels/Spots/Lines') {
          newOptions.cosmeticIssueOptions = newOptions.cosmeticIssueOptions.filter(
            (issue) => issue.header !== 'Damaged Display'
          );
          newOptions.frontScreenOptions = []; // Clear front screen options
          newOptions.frontScreen = null;
        }
      }
    }

    // Cosmetic Issues handling (multiple selection allowed)
    else if (category === 'cosmeticIssues') {
      const alreadySelectedIndex = newOptions.cosmeticIssues.findIndex((selected) => selected._id === option._id);
      if (alreadySelectedIndex >= 0) {
        newOptions.cosmeticIssues.splice(alreadySelectedIndex, 1); // Deselect
        // Reset associated conditions
        if (option.header === 'Damaged Display') {
          newOptions.frontScreen = null;
        } else if (option.header === 'Damaged Back') {
          newOptions.back = null;
        } else if (option.header === 'Damaged Frame') {
          newOptions.side = null;
        }
      } else {
        // Select
        if (option && option.header && option.condition) {
          newOptions.cosmeticIssues.push({
            _id: option._id,
            header: option.header,
            value: option.condition,
            deductionPercentage,
          });
        }
        // Set associated conditions if a specific issue is selected
        if (option.header === 'Damaged Display') {
          newOptions.frontScreen = { header: 'Damaged', value: 'Display is damaged', deductionPercentage };
        } else if (option.header === 'Damaged Back') {
          newOptions.back = { header: 'Damaged', value: 'Back is damaged', deductionPercentage };
        } else if (option.header === 'Damaged Frame') {
          newOptions.side = { header: 'Damaged', value: 'Frame is damaged', deductionPercentage };
        }
      }
    }

    // Handling for frontScreen, back, and side conditions (single selection)
    else if (['frontScreen', 'back', 'side'].includes(category)) {
      if (newOptions[category]?.value === option.condition) {
        newOptions[category] = null; // Deselect
      } else {
        newOptions[category] = { header: option.header, value: option.condition, deductionPercentage }; // Select
      }
    }

    // Handling for isFunctional, isDamaged, isRepaired (single selection)
    else if (category === 'isFunctional') {
      newOptions[category] = option;
      if (option === 'No') {
        newOptions.faults = []; // Reset faults if "No"
        const damagedDisplayOption = product.cosmeticIssues.find((issue) => issue.header === 'Damaged Display');
        if (damagedDisplayOption && !newOptions.cosmeticIssueOptions.some((issue) => issue.header === 'Damaged Display')) {
          newOptions.cosmeticIssueOptions.push(damagedDisplayOption); // Add Damaged Display back if deselected
        }
      }
    } else if (category === 'isDamaged') {
      newOptions[category] = option;
      if (option === 'No') {
        // Reset cosmetic issues and associated conditions if "No"
        newOptions.cosmeticIssues = [];
        newOptions.frontScreen = null;
        newOptions.back = null;
        newOptions.side = null;
      }
    } else if (category === 'isRepaired') {
      newOptions[category] = option;
      if (option === 'No') {
        // Reset repairs if "No"
        newOptions.repair = [];
        newOptions.otherRepairText = '';
      }
    }

    // Handling text inputs (like otherRepairText)
    else if (category === 'otherRepairText') {
      newOptions.otherRepairText = option;
    }

    // Color option selection (single selection)
    else if (category === 'colorOption') {
      const selectedColor = product.colors.find((color) => color.color === option);
      newOptions[category] = { value: selectedColor.color, deductionPercentage: selectedColor.deductionPercentage };
    }

    // SIM option selection (single selection)
    else if (category === 'simOption') {
      newOptions[category] = { value: option, deductionPercentage };
    }

    // Payment option selection (single selection)
else if (category === 'paymentOption') {
  if (newOptions.paymentOption?.option === option.option) {
    newOptions.paymentOption = null; // Deselect
  } else {
    newOptions.paymentOption = {
      option: option.option,
      deductionPercentage: option.deductionPercentage,
    };
  }
}

    // Default handling for other categories
    else {
      if (newOptions[category]?.value === option) {
        newOptions[category] = null; // Deselect
      } else {
        newOptions[category] = { value: option, deductionPercentage }; // Select
      }
    }

    return newOptions;
  });
};

const isStepValid = useCallback(() => {
  // Step 1: Storage and Color selection validation
  if (step === 1) {
    return selectedOptions.storageSize && selectedOptions.colorOption;
  }

  // Step 2: Functionality and Cosmetic Issues validation
  if (step === 2) {
    // Check if functionality has been selected
    if (!selectedOptions.isFunctional) return false;

    // If "Yes" is selected for isFunctional, at least one fault should be selected
    if (selectedOptions.isFunctional === 'Yes' && !selectedOptions.faults.some(fault => fault.header && fault.value)) {
      return false;
    }

    // Check if "IsDamaged" is selected
    if (!selectedOptions.isDamaged) return false;

    // If "Yes" is selected for isDamaged, at least one cosmetic issue should be selected
    if (selectedOptions.isDamaged === 'Yes') {
      // Allow moving forward if at least one cosmetic issue is selected
      if (!selectedOptions.cosmeticIssues.some(issue => issue.header)) return false;

      // Conditional validation: Front Screen
      if (
        selectedOptions.frontScreenOptions?.length > 0 && 
        selectedOptions.cosmeticIssues.some(issue => issue.header === 'Damaged Display') &&
        !selectedOptions.frontScreen?.value
      ) {
        return false;
      }

      // Conditional validation: Back
      if (
        selectedOptions.backOptions?.length > 0 && 
        selectedOptions.cosmeticIssues.some(issue => issue.header === 'Damaged Back') &&
        !selectedOptions.back?.value
      ) {
        return false;
      }

      // Conditional validation: Side
      if (
        selectedOptions.sideOptions?.length > 0 && 
        selectedOptions.cosmeticIssues.some(issue => issue.header === 'Damaged Frame') &&
        !selectedOptions.side?.value
      ) {
        return false;
      }
    }

    return true;
  }

  // Step 3: Repair validation
  if (step === 3) {
    if (!selectedOptions.isRepaired) return false;
    if (selectedOptions.isRepaired === 'Yes' && (!selectedOptions.repair.length || !selectedOptions.repair.some(repair => repair.repair && repair.value))) {
      return false;
    }
    return true;
  }

  // Step 4: Cosmetic Condition validation
  if (step === 4) {
    // If "No" is selected for isDamaged, all options in cosmetic condition must be selected
    if (selectedOptions.isDamaged === 'No') {
      if (!selectedOptions.frontScreen || !selectedOptions.back || !selectedOptions.side) {
        return false;
      }
    }

    // If "Yes" is selected for isDamaged, check if selections are made based on the previous choices
    if (selectedOptions.isDamaged === 'Yes') {
      if (!selectedOptions.cosmeticIssues.some(issue => issue.header && issue.value)) return false;

      // Check front screen if "Damaged Display" is not selected
      if (!selectedOptions.cosmeticIssues.some(issue => issue.header === 'Damaged Display') &&
          selectedOptions.frontScreenOptions?.length > 0 &&
          !selectedOptions.frontScreen?.value) {
        return false;
      }

      // Check back if "Damaged Back" is not selected
      if (!selectedOptions.cosmeticIssues.some(issue => issue.header === 'Damaged Back') &&
          selectedOptions.backOptions?.length > 0 &&
          !selectedOptions.back?.value) {
        return false;
      }

      // Check side if "Damaged Frame" is not selected
      if (!selectedOptions.cosmeticIssues.some(issue => issue.header === 'Damaged Frame') &&
          selectedOptions.sideOptions?.length > 0 &&
          !selectedOptions.side?.value) {
        return false;
      }
    }

    return true;
  }

  // Step 5: Accessories and PTA validation
  if (step === 5) {
    if (!selectedOptions.accessories?.value) return false;
    if (!selectedOptions.pta['Is Your Phone Official PTA Approved?']?.value) return false;
    if (!selectedOptions.pta['Is Your Phone CPID Approved?']?.value) return false;
    if (!selectedOptions.pta['Is Your Phone Patched?']?.value) return false;
    return true;
  }

  // Step 6: Payment Option validation
  if (step === 6) {
    return selectedOptions.paymentOption;
  }

  // Step 7: Customer Details validation
  if (step === 7) {
    return (
      customerDetails.fullName &&
      customerDetails.whatsapp &&
      customerDetails.isInLahore &&
      customerDetails.buyingPreference &&
      (customerDetails.buyingPreference !== 'representative' || customerDetails.address)
    );
  }

  return true;
}, [step, selectedOptions, customerDetails]);


  const handlePtaSelection = (ptaOptionName, option) => {
    setErrorMessage('');

    const selectedPtaOption = product.pta.find((pta) => pta.option === ptaOptionName);

    const deductionPercentage =
      option === 'No' && selectedPtaOption ? selectedPtaOption.deductionPercentage : 0;

    setSelectedOptions((prevOptions) => {
      const newOptions = { ...prevOptions };
      newOptions.pta[ptaOptionName] = { value: option, deductionPercentage };
      return newOptions;
    });
  };

const validateSelection = () => {
  if (!selectedOptions.storageSize && step === 1) {
    setErrorMessage('Please select a storage size.');
    return false;
  }
  if (!selectedOptions.colorOption && step === 1) {
    setErrorMessage('Please select a color option.');
    return false;
  }

  if (step === 2) {
    if (!selectedOptions.isFunctional) {
      setErrorMessage('Please select if your phone is fully functional.');
      return false;
    }

    if (selectedOptions.isFunctional === 'Yes' && !selectedOptions.faults.some(fault => fault.header && fault.value)) {
      setErrorMessage('Please select at least one fault option.');
      return false;
    }
    if (!selectedOptions.isDamaged) {
      setErrorMessage('Please select if your phone is damaged or broken.');
      return false;
    }
    if (selectedOptions.cosmeticIssues.some(issue => issue.header === 'Damaged Display') && !selectedOptions.frontScreen) {
      setErrorMessage('Please select a front screen condition.');
      return false;
    }

    if (selectedOptions.cosmeticIssues.some(issue => issue.header === 'Damaged Back') && !selectedOptions.back) {
      setErrorMessage('Please select a back condition.');
      return false;
    }

    if (selectedOptions.cosmeticIssues.some(issue => issue.header === 'Damaged Frame') && !selectedOptions.side) {
      setErrorMessage('Please select a side condition.');
      return false;
    }
  }

  if (step === 3) {
  if (!selectedOptions.isRepaired) {
    setErrorMessage('Please select if anything on your phone is repaired or replaced.');
    return false;
  }

  if (selectedOptions.isRepaired === 'Yes') {
    // Ensure at least one repair option is selected based on the repair field (not header)
    if (selectedOptions.repair.length === 0 || !selectedOptions.repair.some(repair => repair.repair && repair.value)) {
      setErrorMessage('Please select at least one repair option.');
      return false;
    }
  }
}

if (step === 4) {
  if (!selectedOptions.isDamaged) {
    setErrorMessage('Please select if your phone is damaged or broken.');
    return false;
  }

  if (selectedOptions.isDamaged === 'Yes') {
    if (!selectedOptions.cosmeticIssues.some(issue => issue.header && issue.value)) {
      setErrorMessage('Please select at least one cosmetic issue.');
      return false;
    }

    // Conditional validation for Front Screen
    if (!selectedOptions.cosmeticIssues.some((issue) => issue.header === 'Damaged Display') &&
        !selectedOptions.faults.some((fault) => fault.condition === 'Dead Pixels/Spots/Lines') &&
        selectedOptions.frontScreenOptions?.length > 0 &&
        !selectedOptions.frontScreen?.value) {
      setErrorMessage('Please select the condition of the display.');
      return false;
    }

    // Conditional validation for Back
    if (!selectedOptions.cosmeticIssues.some((issue) => issue.header === 'Damaged Back') &&
        selectedOptions.backOptions?.length > 0 &&
        !selectedOptions.back?.value) {
      setErrorMessage('Please select the condition of the back.');
      return false;
    }

    // Conditional validation for Side
    if (!selectedOptions.cosmeticIssues.some((issue) => issue.header === 'Damaged Frame') &&
        selectedOptions.sideOptions?.length > 0 &&
        !selectedOptions.side?.value) {
      setErrorMessage('Please select the condition of the sides.');
      return false;
    }
  }
}

  if (step === 5) {
    if (!selectedOptions.accessories?.value) {
      setErrorMessage('Please select an accessory option.');
      return false;
    }
    if (!selectedOptions.pta['Is Your Phone Official PTA Approved?']?.value) {
      setErrorMessage('Please select if your Phone is Official PTA approved.');
      return false;
    }
    if (!selectedOptions.pta['Is Your Phone CPID Approved?']?.value) {
      setErrorMessage('Please select if your Phone is CPID approved.');
      return false;
    }
    if (!selectedOptions.pta['Is Your Phone Patched?']?.value) {
      setErrorMessage('Please select if your Phone is patched.');
      return false;
    }
  }

  if (step === 6) {
    if (!selectedOptions.paymentOption)
    {
      setErrorMessage('Please select a payment option.');
      return false;
    }
  }

  if (step === 7) {
  if (!customerDetails.fullName) {
    setErrorMessage('Please enter your full name.');
    return false;
  }
  if (!customerDetails.whatsapp || customerDetails.whatsapp.length < 11) { // +92XXXXXXXXXX has 13 characters
    setErrorMessage('Please enter a valid WhatsApp number.');
    return false;
  }
  if (!customerDetails.isInLahore) {
    setErrorMessage('Please select if you live in Lahore.');
    return false;
  }
  if (!customerDetails.buyingPreference) {
    setErrorMessage('Please select your buying preference.');
    return false;
  }
  if (customerDetails.buyingPreference === 'representative' && !customerDetails.address) {
    setErrorMessage('Please enter your address.');
    return false;
  }
}


  setErrorMessage('');
  return true;
};

const handleNext = () => {
  if (!validateSelection()) {
    return;
  }

  setStep((prevStep) => {
    const newStep = prevStep + 1;

    // For the Payment Options step (step 6), set storeCreditValue and instantCashValue once
    if (newStep === 6) {
      // Store Credit should remain the final price
      setStoreCreditValue(finalPrice); // No further deductions

      // Instant Cash calculation based on its deduction percentage
      const instantCashOption = selectedOptions.paymentOptions?.find(
        (option) => option.option === 'Instant Cash'
      );
      if (instantCashOption) {
        const instantCashDeduction = Math.round(
          product.maxPrice * (instantCashOption.deductionPercentage / 100)
        );
        setInstantCashValue(finalPrice - instantCashDeduction);
      }
    }

    return newStep;
  });

  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const handlePrevious = () => {
    setStep((prevStep) => {
      const newStep = prevStep - 1;
      calculateFinalPrice(selectedOptions);
      return newStep;
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const handleSubmit = async () => {
  if (!validateSelection()) {
    return;
  }

  try {
    // Fetch IP and location data
    const ipLocationResponse = await axiosInstance.get('/api/inspections/get-ip-location');
    const { ip, city, region, country, latitude, longitude } = ipLocationResponse.data;

    const isInLahore = selectedOptions.isInLahore === 'Yes' ? true : false;

    // Construct the inspection data
    const inspectionData = {
      modelName: product.modelName,
      maxPrice: product.maxPrice,
      finalPrice,
      storageSize: selectedOptions.storageSize?.value,
      colorOption: selectedOptions.colorOption?.value,
      simOption: selectedOptions.simOption?.value,
      isFunctional: selectedOptions.isFunctional,
      faults: selectedOptions.isFunctional === 'Yes' ? selectedOptions.faults.map(fault => ({
        header: fault.header, 
        description: fault.value
      })) : [],
      isRepaired: selectedOptions.isRepaired,
      repair: selectedOptions.isRepaired === 'Yes' ? selectedOptions.repair.map(repair => ({
        repair: repair.repair, 
      })) : [],
      isDamaged: selectedOptions.isDamaged,
      cosmeticIssues: selectedOptions.isDamaged === 'Yes' ? selectedOptions.cosmeticIssues.map(issue => ({
        header: issue.header, 
        description: issue.value
      })) : [],
      frontScreen: selectedOptions.frontScreen?.value,
      back: selectedOptions.back?.value,
      side: selectedOptions.side?.value,
      pta: selectedOptions.pta,
      accessories: selectedOptions.accessories?.value,
        paymentOption: {
        option: selectedOptions.paymentOption?.option,
        deductionPercentage: selectedOptions.paymentOption?.deductionPercentage
      },
      // Customer details
      fullName: customerDetails.fullName,
      whatsapp: customerDetails.whatsapp,
      isInLahore,
      address: customerDetails.address,
      buyingPreference: customerDetails.buyingPreference,
      // IP and Location
      ipAddress: ip,
      location: { city, region, country, latitude, longitude },
    };

    await axiosInstance.post('/api/inspections', inspectionData);

    // Show confirmation dialog and redirect
    setShowPopup(true);
    setTimeout(() => {
      navigate('/'); // Redirect to home page after 3 seconds
    }, 3000); // 3000 milliseconds (3 seconds)
  } catch (error) {
    console.error('Error submitting inspection:', error);
    setErrorMessage('Failed to submit inspection. Please try again.');
  }
};

return (
  <div className="container px-2 mx-auto sm:mt-12 sm:mb-12 md:mb-18 md:mt-18 lg:mt-24 lg:mb-24">
    {product && (
      <>
        {/* Headings based on the step */}
        <div className={isMobile ? 'mt-64' : 'mt-0'}>
        {!isMobile && (
        <div className='justify-center'>
  {step === 1 && (
    <>
      <h2 className="text-left text-2xl font-semibold">Variant Options</h2>
      <p>Go to Settings → About → to check the storage capacity.</p>
    </>
  )}
  
  {step === 2 && (
    <>
      <h2 className="text-left text-2xl font-semibold">Functionality</h2>
      <p>Select any functional issues you are experiencing with your device.</p>
    </>
  )}

  {step === 3 && (
    <>
      <h2 className="text-left text-2xl font-semibold">Repair History</h2>
      <p>Indicate if any parts of your phone have been repaired or replaced.</p>
    </>
  )}

  {step === 4 && (
    <>
      <h2 className="text-left text-2xl font-semibold">Cosmetic Condition</h2>
      <p>Choose the current condition of the phone's external parts (e.g., screen, back, sides).</p>
    </>
  )}

  {step === 5 && (
    <>
      <h2 className="text-left text-2xl font-semibold">Accessories & Custom Duty</h2>
      <p>Select the accessories that come with your phone, if any</p>
    </>
  )}
    {step === 6 && (
    <>
      <h2 className='text-left text-2xl font-semibold'>Estimated Device Value</h2>
      <p></p>
    </>
  )}
  {step === 7 && (
    <>
      <h2 className='text-left text-2xl font-semibold'>Customer Details</h2>
      <p></p>
    </>
  )}
</div>
)}
</div>

        {/* Main content */}
        <div className={`flex flex-col md:flex-row gap-4 ${isMobile ? 'justify-center' : ''}`}>
        <div 
  className={`md:w-2/3 ${isMobile ? 'w-full mx-auto' : ''} order-2 md:order-1`}
  style={{
    marginTop: isMobile ? '0.5rem' : '1rem', // Adjusting for mobile and desktop
    paddingTop: isMobile ? '0.5rem' : '1rem', // Reduce padding on mobile to minimize white space
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'flex-start', // Ensures content starts from the top
  }}
>

<div className="bg-white shadow-lg rounded-lg p-4 mb-12">
              {/* Sticky image for mobile */}
{isMobile && (
  <div className="fixed top-14 left-0 right-0 bg-white z-50 p-4 shadow-md">
    <div className="relative">
      <button
        onClick={() => {
          if (step === 1) {
            navigate('/samsung');
          } else {
            handlePrevious();
          }
        }}
        className="absolute top-0 left-0 p-2 bg-white rounded-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <img
        src={
          product.colors.find((color) => color.color === selectedOptions.colorOption?.value)?.image ||
          product.colors[0]?.image
        }
        alt={product.modelName}
        className="w-full h-40 object-contain"
      />
      <p className="text-center text-lg font-semibold mt-2">
        {product.modelName} {selectedOptions.storageSize && `(${selectedOptions.storageSize.value})`}
      </p>
    </div>
  </div>
)}

              {/* Step-based content */}
              <div className={isMobile ? 'mt-0' : 'mt-0'}>
                {/* Step 1: Variant Options */}
                {step === 1 && (
                  <div>
                    <h3 className="mt-4 text-lg font-semibold">Color</h3>
                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
                      {product.colors.map((color) => (
                        <button
                          key={color._id}
                          className={`py-2 px-4 rounded-lg border ${
                            selectedOptions.colorOption && selectedOptions.colorOption.value === color.color
                              ? 'bg-black text-white font-semibold'
                              : 'bg-white text-black border-gray-300 font-semibold'
                          }`}
                          onClick={() => handleSelection('colorOption', color.color)}
                        >
                          {color.color}
                        </button>
                      ))}
                    </div>

                    <h3 className="mt-4 text-lg font-semibold">Storage</h3>
                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
                      {product.storageSizes.map((size) => (
                        <button
                          key={size._id}
                          className={`py-2 px-4 rounded-lg border ${
                            selectedOptions.storageSize && selectedOptions.storageSize.value === size.size
                              ? 'bg-black text-white font-semibold'
                              : 'bg-white text-black border-gray-300 font-semibold'
                          }`}
                          onClick={() => handleSelection('storageSize', size.size, size.deductionPercentage)}
                        >
                          {size.size}
                        </button>
                      ))}
                    </div>
                        <h3 className="mt-4 text-lg font-semibold">SIM Variant</h3>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
                          {selectedOptions.simOptions?.map((option) => (
                            <button
                              key={option._id}
                              className={`py-2 px-4 rounded-lg border ${
                                selectedOptions.simOption?.value === option.option
                                  ? 'bg-black text-white font-semibold'
                                  : 'bg-white text-black border-gray-300 font-semibold'
                              }`}
                              onClick={() => handleSelection('simOption', option.option, option.deductionPercentage)}
                            >
                              {option.option}
                            </button>
                          ))}
                        </div>
                  </div>
                )}

                {/* Step 2: Functionality */}
                {step === 2 && (
                  <div>
                    <h3 className="mt-8 mb-4 text-lg font-semibold">Does your phone have any functional problem(s)?</h3>
                    <div className="flex gap-2 mt-2">
                      <button
                        className={`py-2 px-4 rounded-lg ${
                          selectedOptions.isFunctional === 'Yes' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                        }`}
                        onClick={() => handleSelection('isFunctional', 'Yes')}
                      >
                        Yes
                      </button>
                      <button
                        className={`py-2 px-4 rounded-lg ${
                          selectedOptions.isFunctional === 'No' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                        }`}
                        onClick={() => handleSelection('isFunctional', 'No')}
                      >
                        No
                      </button>
                    </div>
                    {selectedOptions.isFunctional === 'Yes' && (
                      <>
                        <h3 className="mt-4 mb-4 text-lg font-semibold">Please select the problem you are facing:</h3>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
                          {selectedOptions.faultOptions?.map((option) => (
                            <div
                              key={option._id}
                              className={`relative p-2 rounded-lg cursor-pointer border ${
                                selectedOptions.faults.some((selected) => selected._id === option._id)
                                  ? 'border-black font-semibold'
                                  : 'border-gray-300 bg-white font-semibold'
                              }`}
                              onClick={() => handleSelection('faults', option, option.deductionPercentage)}
                            >
                              <img src={option.image} alt={option.condition} className="h-20 w-full object-contain" />
                              <p className={`text-center mt-2 font-semibold ${
                                selectedOptions.faults.some((selected) => selected._id === option._id) ? 'bg-blue-50' : ''
                              }`}>
                                {option.header}
                              </p>
                              <p className={`text-center text-sm ${
                                selectedOptions.faults.some((selected) => selected._id === option._id) ? 'bg-blue-50' : ''
                              }`}>
                                {option.condition}
                              </p>
                              {selectedOptions.faults.some((selected) => selected._id === option._id) && (
                                <CheckCircleIcon className="absolute top-2 right-2 h-6 w-6 text-black" />
                              )}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                      <div>
    <h3 className="mt-4 mb-4 text-lg font-semibold">Is your phone damaged or broken?</h3>
    <div className="flex gap-2 mt-4">
      <button
        className={`py-2 px-4 rounded-lg ${
          selectedOptions.isDamaged === 'Yes' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
        }`}
        onClick={() => handleSelection('isDamaged', 'Yes')}
      >
        Yes
      </button>
      <button
        className={`py-2 px-4 rounded-lg ${
          selectedOptions.isDamaged === 'No' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
        }`}
        onClick={() => handleSelection('isDamaged', 'No')}
      >
        No
      </button>
    </div>

    {selectedOptions.isDamaged === 'Yes' && (
      <>
        <h3 className="mt-4 mb-4 text-lg font-semibold">Please select the damaged part(s):</h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
          {selectedOptions.cosmeticIssueOptions
            ?.filter(
              (option) =>
                option.header !== 'Damaged Display' ||
                !selectedOptions.faults.some((fault) => fault.condition === 'Dead Pixels/Spots/Lines')
            )
            .map((option) => (
              <div
                key={option._id}
                className={`relative p-2 rounded-lg cursor-pointer border ${
                  selectedOptions.cosmeticIssues.some((selected) => selected._id === option._id)
                    ? 'border-black font-semibold'
                    : 'border-gray-300 bg-white font-semibold'
                }`}
                onClick={() => handleSelection('cosmeticIssues', option, option.deductionPercentage)}
              >
                <img src={option.image} alt={option.condition} className="h-20 w-full object-contain" />
                <p className={`text-center mt-4 font-semibold ${
                  selectedOptions.cosmeticIssues.some((selected) => selected._id === option._id) ? 'bg-blue-50' : ''
                }`}>
                  {option.header}
                </p>
                <p className={`text-center text-sm ${
                  selectedOptions.cosmeticIssues.some((selected) => selected._id === option._id) ? 'bg-blue-50' : ''
                }`}>
                  {option.condition}
                </p>
                {selectedOptions.cosmeticIssues.some((selected) => selected._id === option._id) && (
                  <CheckCircleIcon className="absolute top-2 right-2 h-6 w-6 text-black" />
                )}
              </div>
            ))}
        </div>
      </>
    )}
  </div>
  </div>
                )}

                {/* Step 3: Repair History */}
                {step === 3 && (
                  <div>
                    <h3 className="mt-4 text-lg font-semibold">Is any part on your phone repaired or replaced?</h3>
                    <div className="flex gap-2 mt-4 mb-4">
                      <button
                        className={`py-2 px-4 rounded-lg ${
                          selectedOptions.isRepaired === 'Yes' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                        }`}
                        onClick={() => handleSelection('isRepaired', 'Yes')}
                      >
                        Yes
                      </button>
                      <button
                        className={`py-2 px-4 rounded-lg ${
                          selectedOptions.isRepaired === 'No' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                        }`}
                        onClick={() => handleSelection('isRepaired', 'No')}
                      >
                        No
                      </button>
                    </div>

                    {selectedOptions.isRepaired === 'Yes' && (
                      <>
                        <h3 className="mt-4 mb-4 text-lg font-semibold">Please select the repair/replacement done:</h3>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                          {selectedOptions.repairOptions?.map((option) => (
                            <div
                              key={option.repair}
                              className={`relative p-2 rounded-lg cursor-pointer border ${
                                selectedOptions.repair.some((selected) => selected.repair === option.repair)
                                  ? 'border-black font-semibold'
                                  : 'border-gray-300 bg-white font-semibold'
                              }`}
                              onClick={() => handleSelection('repair', option, option.deductionPercentage)}
                            >
                              <img src={option.image} alt={option.repair} className="h-20 w-full object-contain" />
                              <p className={`text-center mt-4 font-semibold ${
                                selectedOptions.repair.some((selected) => selected.repair === option.repair) ? 'bg-blue-50' : ''
                              }`}>
                                {option.repair}
                              </p>
                              {selectedOptions.repair.some((selected) => selected.repair === option.repair) && (
                                <CheckCircleIcon className="absolute top-2 right-2 h-6 w-6 text-black" />
                              )}
                            </div>
                          ))}
                        </div>

                        {selectedOptions.repair.some((selected) => selected.repair === 'Other Repairs') && (
                          <div className="mt-4 mb-4">
                            <h3 className="text-lg font-semibold">Please describe what was repaired or replaced (max 140 characters):</h3>
                            <input
                              type="text"
                              maxLength={140}
                              value={selectedOptions.otherRepairText}
                              onChange={(e) => handleSelection('otherRepairText', e.target.value)}
                              placeholder="Describe the repair or replacement..."
                              className="w-full p-2 mt-2 border font-semibold border-gray-300 rounded-lg"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}


                {/* Step 4: Cosmetic Condition */}
{step === 4 && (
  <div>

    {!selectedOptions.cosmeticIssues.some((issue) => issue.header === 'Damaged Display') &&
      !selectedOptions.faults.some((fault) => fault.condition === 'Dead Pixels/Spots/Lines') &&
      selectedOptions.frontScreenOptions?.length > 0 && (
        <>
          <h3 className="mt-4 mb-4 text-lg font-semibold">What is the condition of the display?</h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
            {selectedOptions.frontScreenOptions?.map((option) => (
              <div
                key={option._id}
                className={`relative p-2 rounded-lg cursor-pointer border ${
                  selectedOptions.frontScreen?.value === option.condition
                    ? 'border-black font-semibold'
                    : 'border-gray-300 bg-white font-semibold'
                }`}
                onClick={() => handleSelection('frontScreen', option, option.deductionPercentage)}
              >
                <img src={option.image} alt={option.condition} className="h-20 w-full object-contain" />
                <p className={`text-center mt-4 font-semibold ${
                  selectedOptions.frontScreen?.value === option.condition ? 'bg-blue-50' : ''
                }`}>
                  {option.header}
                </p>
                <p className={`text-center text-sm font-semibold ${
                  selectedOptions.frontScreen?.value === option.condition ? 'bg-blue-50' : ''
                }`}>
                  {option.condition}
                </p>
                {selectedOptions.frontScreen?.value === option.condition && (
                  <CheckCircleIcon className="absolute top-2 right-2 h-6 w-6 text-black" />
                )}
              </div>
            ))}
          </div>
        </>
      )}

    {!selectedOptions.cosmeticIssues.some((issue) => issue.header === 'Damaged Back') && (
      <>
        <h3 className="mt-4 mb-4 text-lg font-semibold">What is the condition of the back?</h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
          {selectedOptions.backOptions?.map((option) => (
            <div
              key={option._id}
              className={`relative p-2 rounded-lg cursor-pointer border ${
                selectedOptions.back?.value === option.condition ? 'border-black font-semibold' : 'border-gray-300 bg-white font-semibold'
              }`}
              onClick={() => handleSelection('back', option, option.deductionPercentage)}
            >
              <img src={option.image} alt={option.condition} className="h-20 w-full object-contain" />
              <p className={`text-center mt-4 font-semibold ${
                selectedOptions.back?.value === option.condition ? 'bg-blue-50' : ''
              }`}>
                {option.header}
              </p>
              <p className={`text-center text-sm font-semibold ${
                selectedOptions.back?.value === option.condition ? 'bg-blue-50' : ''
              }`}>
                {option.condition}
              </p>
              {selectedOptions.back?.value === option.condition && (
                <CheckCircleIcon className="absolute top-2 right-2 h-6 w-6 text-black" />
              )}
            </div>
          ))}
        </div>
      </>
    )}

    {!selectedOptions.cosmeticIssues.some((issue) => issue.header === 'Damaged Frame') && (
      <>
        <h3 className="mt-4 mb-4 text-lg font-semibold">What is the condition of the sides?</h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
          {selectedOptions.sideOptions?.map((option) => (
            <div
              key={option._id}
              className={`relative p-2 rounded-lg cursor-pointer border ${
                selectedOptions.side?.value === option.condition ? 'border-black font-semibold' : 'border-gray-300 bg-white font-semibold'
              }`}
              onClick={() => handleSelection('side', option, option.deductionPercentage)}
            >
              <img src={option.image} alt={option.condition} className="h-20 w-full object-contain" />
              <p className={`text-center mt-4 font-semibold ${
                selectedOptions.side?.value === option.condition ? 'bg-blue-50' : ''
              }`}>
                {option.header}
              </p>
              <p className={`text-center text-sm ${
                selectedOptions.side?.value === option.condition ? 'bg-blue-50' : ''
              }`}>
                {option.condition}
              </p>
              {selectedOptions.side?.value === option.condition && (
                <CheckCircleIcon className="absolute top-2 right-2 h-6 w-6 text-black" />
              )}
            </div>
          ))}
        </div>
      </>
    )}
  </div>
)}
                {/* Step 5: Accessories */}
                {step === 5 && (
                  <div>
                    <h3 className="mt-4 mb-4 text-lg font-semibold">Accessories</h3>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
  {selectedOptions.accessoriesOptions?.map((option) => (
    <button
      key={option._id}
      className={`py-2 px-4 rounded-lg border ${
        selectedOptions.accessories?.value === option.option
          ? 'bg-black text-white font-semibold'
          : 'bg-white text-black border-gray-300 font-semibold'
      }`}
      onClick={() => handleSelection('accessories', option.option, option.deductionPercentage)}
    >
      {option.option}
    </button>
  ))}
</div>

                      <div>
                    <h3 className="mt-8 mb-4 text-lg font-semibold">Is Your Phone Official PTA Approved?</h3>
                    <div className="flex gap-2 mt-4">
                      <button
                        className={`py-2 px-4 rounded-lg ${
                          selectedOptions.pta['Is Your Phone Official PTA Approved?']?.value === 'Yes'
                            ? 'bg-black font-semibold text-white'
                            : 'bg-gray-100 text-black font-semibold'
                        }`}
                        onClick={() => handlePtaSelection('Is Your Phone Official PTA Approved?', 'Yes')}
                      >
                        Yes
                      </button>
                      <button
                        className={`py-2 px-4 rounded-lg ${
                          selectedOptions.pta['Is Your Phone Official PTA Approved?']?.value === 'No'
                            ? 'bg-black font-semibold text-white'
                            : 'bg-gray-100 text-black font-semibold'
                        }`}
                        onClick={() => handlePtaSelection('Is Your Phone Official PTA Approved?', 'No')}
                      >
                        No
                      </button>
                    </div>

                    {selectedOptions.pta['Is Your Phone Official PTA Approved?']?.value === 'No' && (
                        <>
                        <h3 className="mt-4 mb-4 text-lg font-semibold">Is Your Phone CPID Approved?</h3>
                        <div className="flex gap-2 mt-4">
                          <button
                            className={`py-2 px-4 rounded-lg ${
                              selectedOptions.pta['Is Your Phone CPID Approved?']?.value === 'Yes'
                                ? 'bg-black text-white font-semibold'
                                : 'bg-gray-100 text-black font-semibold'
                            }`}
                            onClick={() => handlePtaSelection('Is Your Phone CPID Approved?', 'Yes')}
                          >
                            Yes
                          </button>
                          <button
                            className={`py-2 px-4 rounded-lg ${
                              selectedOptions.pta['Is Your Phone CPID Approved?']?.value === 'No'
                                ? 'bg-black text-white font-semibold'
                                : 'bg-gray-100 text-black font-semibold'
                            }`}
                            onClick={() => handlePtaSelection('Is Your Phone CPID Approved?', 'No')}
                          >
                            No
                          </button>
                        </div>
                        </>
                    )}

                    {selectedOptions.pta['Is Your Phone CPID Approved?']?.value === 'No' && (
                        <>
                        <h3 className="mt-4 mb-4 text-lg font-semibold">Is Your Phone Patched?</h3>
                        <div className="flex gap-2 mt-4">
                            <button
                                className={`py-2 px-4 rounded-lg ${
                                selectedOptions.pta['Is Your Phone Patched?']?.value === 'Yes'
                                    ? 'bg-black text-white font-semibold'
                                    : 'bg-gray-100 text-black font-semibold'
                                }`}
                                onClick={() => handlePtaSelection('Is Your Phone Patched?', 'Yes')}
                            >
                                Yes
                            </button>
                            <button
                                className={`py-2 px-4 rounded-lg ${
                                selectedOptions.pta['Is Your Phone Patched?']?.value === 'No'
                                    ? 'bg-black text-white font-semibold'
                                    : 'bg-gray-100 text-black font-semibold'
                                }`}
                                onClick={() => handlePtaSelection('Is Your Phone Patched?', 'No')}
                            >
                                No
                            </button>
                            </div>
                            </>
                    )}
                  </div>
                  </div>
                )}
                          {/* Payment Selection Step */}
                          {step === 6 && (
  <div>
    <h3 className="mt-4 mb-4 text-lg font-semibold">Please select from the following payment options: </h3>
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
      {selectedOptions.paymentOptions.map((option) => (
        <button
          key={option._id}
          className={`py-2 px-4 rounded-lg border ${
            selectedOptions.paymentOption?.option === option.option
              ? 'bg-black text-white font-semibold'
              : 'bg-white text-black border-gray-300 font-semibold'
          }`}
          onClick={() => handleSelection('paymentOption', option)}
        >
          {option.option === 'Instant Cash'
            ? `Instant Cash (Rs: ${instantCashValue.toLocaleString()})`  
            : `Store Credit (Rs: ${storeCreditValue.toLocaleString()})`  
          }
        </button>
      ))}
    </div>

    {/* Tooltip with indigo background */}
    <div className="relative mt-4 bg-indigo-100 text-indigo-900 p-4 rounded-lg shadow-lg w-full">
      <strong>Why Choose Store Credit?</strong>
      <ul className="mt-2 text-sm">
        <li>- Store Credit offers a higher value compared to Instant Cash.</li>
        <li>- You can use this Credit to buy an upgrade from our Store or cash it out to your bank account after 7 days.</li>
      </ul>
    </div>
  </div>
)}



{step === 7 && (
  <div>
    <div className="mt-4">
      <div className="w-10/12">
        <input
          type="text"
          value={customerDetails.fullName}
          onChange={(e) => handleCustomerDetailsChange('fullName', e.target.value)}
          placeholder="Enter your name here..."
          className="w-full p-2 mt-2 border font-semibold border-gray-300 rounded-lg"
        />
      </div>
    </div>

<div className="mt-4">
  <div className="w-10/12">
    <input
      type="text"
      value={customerDetails.whatsapp}
      onChange={(e) => {
        let input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        handleCustomerDetailsChange('whatsapp', input); // Set the number as is
      }}
      placeholder="(03XXXXXXXXX)"
      className="w-full p-2 mt-2 border font-semibold border-gray-300 rounded-lg"
      maxLength={11} // Limit the input to 11 characters
    />
  </div>
</div>





    <div className="mt-4">
      <label className="block font-semibold">Do you live in Lahore?</label>
      <div className="flex gap-2 mt-2">
        <button
          className={`py-2 px-4 rounded-lg ${
            customerDetails.isInLahore === 'Yes' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
          }`}
          onClick={() => handleCustomerDetailsChange('isInLahore', 'Yes')}
        >
          Yes
        </button>
        <button
          className={`py-2 px-4 rounded-lg ${
            customerDetails.isInLahore === 'No' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
          }`}
          onClick={() => handleCustomerDetailsChange('isInLahore', 'No')}
        >
          No
        </button>
      </div>
    </div>

    <div className="mt-4">
      <label className="block font-semibold">How would you like us to buy your phone?</label>
      <div className="flex gap-2 mt-2">
        <button
          className={`py-2 px-4 rounded-lg ${
            customerDetails.buyingPreference === 'bring' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
          }`}
          onClick={() => handleCustomerDetailsChange('buyingPreference', 'bring')}
        >
          I'll bring/send it to your office
        </button>
        <button
          className={`py-2 px-4 rounded-lg ${
            customerDetails.buyingPreference === 'representative' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
          }`}
          onClick={() => handleCustomerDetailsChange('buyingPreference', 'representative')}
        >
          Send your representative to my address
        </button>
      </div>
    </div>

    {/* Show address input field conditionally */}
    {customerDetails.buyingPreference === 'representative' && (
      <div className="mt-4">
        <label className="block font-semibold">Enter your address:</label>
        <input
          type="text"
          value={customerDetails.address || ''}
          onChange={(e) => handleCustomerDetailsChange('address', e.target.value)}
          placeholder="Enter your address here"
          className="w-full p-2 mt-2 border font-semibold border-gray-300 rounded-lg"
        />
      </div>
    )}
  </div>
)}
                {/* Buttons for navigation */}
<div 
  className={`${
    isMobile ? 'fixed bottom-0 left-0 right-0 w-full z-50 p-4' : 'mt-12 mb-6'
  } flex justify-center items-center gap-4 bg-opacity-70 mx-auto transition-all duration-500`}
>
  {!isMobile && step >= 1 && step <= 7 && (
    <button
      className="py-2 px-4 font-urbanist font-semibold rounded-3xl transition border bg-white text-black hover:bg-gray-100 hover:border-black border-gray-300"
      onClick={() => {
        if (step === 1) {
          navigate('/samsung');
        } else {
          handlePrevious();
        }
      }}
    >
      Go Back
    </button>
  )}

  {!isMobile && step < 7 && (
    <button
      className={`py-2 px-4 font-urbanist font-semibold rounded-3xl transition ${
        isStepValid() ? 'bg-customGreen text-white cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
      }`}
      onClick={isStepValid() ? handleNext : null}
      disabled={!isStepValid()}
    >
      Next
    </button>
  )}

  {isMobile && step < 7 && (
    <button
      className={`py-2 px-4 font-urbanist font-semibold rounded-lg w-full max-w-xs bg-customGreen text-white transition-all duration-500 transform ${
        isStepValid() ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full'
      }`}
      onClick={isStepValid() ? handleNext : null}
      disabled={!isStepValid()}
    >
      Next
    </button>
  )}

  {isMobile && step === 7 && (
    <button
      className={`py-2 px-4 font-urbanist font-semibold rounded-lg w-full max-w-xs bg-customGreen text-white transition-all duration-500 transform ${
        isStepValid() ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full'
      }`}
      onClick={isStepValid() ? handleSubmit : null}
      disabled={!isStepValid()}
    >
      Get exact value
    </button>
  )}

  {!isMobile && step === 7 && (
    <button
      className={`py-2 px-4 font-urbanist font-semibold rounded-3xl transition ${
        isStepValid() ? 'bg-customGreen text-white cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
      }`}
      onClick={isStepValid() ? handleSubmit : null}
      disabled={!isStepValid()}
    >
      Get exact value
    </button>
  )}
</div>
              </div>
            </div>
          </div>

          {/* Sticky right sidebar (desktop only) */}
{!isMobile && (
  <div className="md:w-1/3 order-1 md:order-2">
    <div className="sticky top-16">
      <div className="bg-white shadow-lg rounded-lg p-4">
        <div className="flex gap-2 items-center mb-4">
          <img
            src={
              selectedOptions.colorOption?.value
                ? product.colors.find((color) => color.color === selectedOptions.colorOption?.value)?.image
                : product.colors[0]?.image
            }
            alt={product.modelName}
            className="w-12 h-12 object-contain"
          />
          <p className="font-semibold">
            {product.modelName} {selectedOptions.storageSize && ` (${selectedOptions.storageSize.value})`}
          </p>
        </div>

        <hr className="my-4" />

        <h3 className="text-lg font-semibold">Device Evaluation</h3>
        <ul className="list-disc pl-4 mt-2">
          {selectedOptions.faults.length > 0 && (
            <li>Faults: {selectedOptions.faults.map((fault) => fault.value).join(', ')}</li>
          )}
          {selectedOptions.repair.length > 0 && (
            <li>
              Repair History: {selectedOptions.repair.map((repair) => repair.repair).join(', ')}
            </li>
          )}
          {selectedOptions.cosmeticIssues.length > 0 && (
            <li>Cosmetic Issues: {selectedOptions.cosmeticIssues.map((issue) => issue.value).join(', ')}</li>
          )}
          {selectedOptions.frontScreen && !selectedOptions.cosmeticIssues.some((issue) => issue.header === 'Damaged Display') && (
            <li>Condition of the Display: {selectedOptions.frontScreen.value}</li>
          )}
          {selectedOptions.back && !selectedOptions.cosmeticIssues.some((issue) => issue.header === 'Damaged Back') && (
            <li>Condition of the Back: {selectedOptions.back.value}</li>
          )}
          {selectedOptions.side && !selectedOptions.cosmeticIssues.some((issue) => issue.header === 'Damaged Frame') && (
            <li>Condition of the Sides: {selectedOptions.side.value}</li>
          )}
          {selectedOptions.simOption && <li>SIM Option: {selectedOptions.simOption.value}</li>}
          {selectedOptions.accessories && <li>Accessories: {selectedOptions.accessories.value}</li>}
          {Object.keys(selectedOptions.pta).length > 0 && (
            <li>
              PTA: {Object.entries(selectedOptions.pta).map(([key, value]) => `${key}: ${value.value}`).join(', ')}
            </li>
          )}
          <li>
  Estimated Price: {selectedOptions.paymentOption?.option === 'Instant Cash'
    ? instantCashValue  // Use instantCashValue directly if Instant Cash is selected
    : storeCreditValue  // Use storeCreditValue for Store Credit
  } PKR
</li>
          <li className="font-bold text-lg mt-2">
            Final Price: {finalPrice} PKR
          </li>

        </ul>
      </div>
    </div>
  </div>
)}

        </div>

        {/* Error message */}
        {errorMessage && <div className="mt-4 text-red-500">{errorMessage}</div>}

        {/* Success confirmation */}
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4 md:max-w-md lg:max-w-lg">
              <CheckCircleIcon className="h-12 w-12 text-green-500 mx-auto" />
              <h2 className="text-center text-xl md:text-2xl font-semibold mt-4">Submission Successful!</h2>
              <p className="text-center mt-2 text-sm md:text-base">
                You will be redirected to the home page shortly.
              </p>
            </div>
          </div>
        )}
      </>
    )}
  </div>
);


};

export default SamsungDetails;