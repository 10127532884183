import React, { useState, useEffect } from 'react';
import axiosInstance from './baseURL';
import { Link } from 'react-router-dom';

const Samsung = () => {
    const [device, setDevice] = useState([]);

    useEffect(() => {
        const fetchSamsung = async () => {
            try {
                const response = await axiosInstance.get('api/samsung');
                console.log('Samsung:', response.data);
                setDevice(response.data);
            } catch (error) {
                console.error('Error fetching Samsung:', error);
            }
        };

        fetchSamsung();
    }, []);

      return (
    <div className="container mx-auto px-5 py-10 mt-12">
      <h2 className="text-4xl lg:text-5xl font-semibold text-center font-urbanist mb-12">
        Choose Your Samsung Device
      </h2>
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-12 mx-auto max-w-7xl">
        {device.map((Sam) => {
          const firstColorImage =
            Sam.colors.length > 0 ? Sam.colors[0].image : ''; // Get the image of the first available color

          return (
            <Link
              to={`/product/samsung/?id=${Sam.id}`}
              key={Sam.id}
              className="group block"
            >
              <div className="bg-gray-100 p-6 rounded-3xl shadow-lg transform transition-transform duration-300 group-hover:scale-105">
                {/* Desktop and larger screens */}
                <div className="hidden md:block">
                  <div className="text-center mb-4">
                    <img
                      src={firstColorImage}
                      alt={Sam.modelName}
                      className="h-48 w-auto mx-auto rounded-xl"
                    />
                  </div>
                  <h3 className="text-lg font-bold font-urbanist mb-2 text-center">
                    {Sam.modelName}
                  </h3>
                  <p className="text-gray-500 text-sm font-semibold font-urbanist mb-4 text-center">
                    Get up to Rs: {Sam.maxPrice.toLocaleString()}
                  </p>
                  <div className="flex items-center justify-center bg-white rounded-full shadow-md p-3 w-full">
                    <p className="font-bold font-urbanist text-sm">
                      Get exact value
                    </p>
                  </div>
                </div>

                {/* Phone screens - Row layout */}
                <div className="md:hidden flex items-center justify-between">
                  <div className="w-2/3 pr-4">
                    <h3 className="text-lg font-bold font-urbanist mb-1">
                      {Sam.modelName}
                    </h3>
                    <p className="text-gray-500 text-sm font-semibold font-urbanist mb-4">
                      Get up to Rs: {Sam.maxPrice.toLocaleString()}
                    </p>
                    <div className="flex items-center bg-white rounded-full shadow-md p-3 w-full mt-2">
                      <p className="font-bold font-urbanist text-sm">
                        Get exact value
                      </p>
                    </div>
                  </div>
                  <div className="w-1/3 flex justify-end">
                    <img
                      src={firstColorImage}
                      alt={Sam.modelName}
                      className="h-24 w-auto rounded-xl"
                    />
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Samsung;