import React, { useState, useEffect } from 'react';
import axiosInstance from './baseURL';
import { Link } from 'react-router-dom';

const MacBook = () => {
    const [macBooks, setMacBooks] = useState([]);

    useEffect(() => {
        const fetchMacBooks = async () => {
            try {
                const response = await axiosInstance.get('api/macbooks');
                console.log('MacBooks:', response.data);
                setMacBooks(response.data);
            } catch (error) {
                console.error('Error fetching MacBooks:', error);
            }
        };

        fetchMacBooks();
    }, []);

    return (
        <div className="container mx-auto px-5 py-10 mt-12">
            <h2 className="text-4xl lg:text-5xl font-semibold text-center font-urbanist mb-12">
                Choose Your MacBook
            </h2>
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-12 mx-auto max-w-7xl">
                {macBooks.map((macBook) => {
                    const firstColorImage =
                        macBook.colors.length > 0 ? macBook.colors[0].image : ''; // Get the image of the first available color

                    return (
                        <Link
                            to={`/product/macbook/?id=${macBook.id}`}
                            key={macBook.id}
                            className="group block"
                        >
                            <div className="bg-gray-100 p-6 rounded-3xl shadow-lg transform transition-transform duration-300 group-hover:scale-105">
                                {/* Desktop and larger screens */}
                                <div className="hidden md:block">
                                    <div className="text-center mb-4">
                                        <img
                                            src={firstColorImage}
                                            alt={macBook.modelName}
                                            className="h-48 w-auto mx-auto rounded-xl"
                                        />
                                    </div>
                                    <h3 className="text-lg font-bold font-urbanist mb-2 text-center">
                                        {macBook.modelName}
                                    </h3>
                                    <p className="text-gray-500 text-sm font-semibold font-urbanist mb-4 text-center">
                                        Get up to Rs: {macBook.maxPrice.toLocaleString()}
                                    </p>
                                    <div className="flex items-center justify-center bg-white rounded-full shadow-md p-3 w-full">
                                        <p className="font-bold font-urbanist text-sm">
                                            Get exact value
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );

};

export default MacBook;