import React from 'react';
import { Link } from 'react-router-dom';

const products = [
  {
    name: 'iPhone',
    image: 'https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/tcei37whtrgypxjdjouy',
    description: 'iPhone 8 or later',
    link: '/iphone',
    bgColor: 'bg-pink-100', // Tailwind color for Light Pink
    maxPrice: '430,000',
  },
    {
    name: 'Samsung',
    image: 'https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/hvijmi7sggt0jbwc2re2',
    description: 'Galaxy S, Note, & More',
    link: '/samsung',
    bgColor: 'bg-indigo-100', // Tailwind color for Light Indigo
    maxPrice: '260,000',
  },
  {
    name: 'iPad',
    image: 'https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/bp4ephneohsdjf1mioix',
    description: 'iPad & Accessories',
    link: '/ipad',
    bgColor: 'bg-yellow-100', // Tailwind color for Soft Yellow
    maxPrice: '230,000',
  },
  {
    name: 'MacBook',
    image: 'https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/srmfrevprjsbhryd1eoa',
    description: 'Mac mini to the Pro',
    link: '/macbook',
    bgColor: 'bg-cyan-100', // Tailwind color for Light Cyan
    maxPrice: '600,000',
  },
  {
    name: 'Watch',
    image: 'https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/eiwvrbozilkckwlmneog',
    description: 'Series 3 or later',
    link: '#',
    bgColor: 'bg-green-100', // Tailwind color for Light Green
    maxPrice: '100,000',
  },
  {
    name: 'Accessories',
    image: 'https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/fzzf0d3rw9ovkgh5ydgh',
    description: 'Chargers, Headphones, & More',
    link: '#',
    bgColor: 'bg-purple-100', // Tailwind color for Soft Purple
    maxPrice: '50,000',
  },
];

const Home = () => {
  return (
    <div className="container mx-auto py-8 px-5 lg:px-0">
      {/* Heading */}
      <div className="text-center mb-12">
        <h1 className="text-4xl lg:text-5xl font-semibold font-urbanist mb-2 mt-10">
          Trade-in your devices
        </h1>
        <p className="text-gray-600 text-lg lg:text-xl font-urbanist">
          For Cash or Extra Value Store Credit!
        </p>
      </div>

      {/* Products grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-12 mb-12 max-w-7xl mx-auto">
        {products.map((product) => (
          <Link to={product.link} key={product.name} className="no-underline">
            <div
              className={`${product.bgColor} p-6 rounded-2xl shadow-lg transition-transform duration-300 hover:scale-105`}
            >
              <div className="flex justify-center mb-4">
                <img
                  src={product.image}
                  alt={product.name}
                  className="h-64 object-contain rounded-lg"
                />
              </div>
              <div className="text-center">
                <h2 className="text-xl font-semibold font-urbanist mb-1">
                  {product.name}
                </h2>
                <p className="text-gray-600 mb-3 font-urbanist">
                  {product.description}
                </p>
                {/* Price section */}
                <div className="bg-white rounded-full py-2 px-4 shadow-md">
                  <p className="text-black font-semibold font-urbanist">
                    Get up-to Rs: {product.maxPrice}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Home;
